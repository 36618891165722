import React from 'react';
import apiClient from 'app/utils/api-client';
import Form from 'app/common/Form';
import FormInput from 'app/common/FormInput';

type Props = {
    data: {
        id: string,
        name: string,
        currency:string,
        period: string,
        recurring: number,
        signup: number,
        trialPeriod: string,
        oneOff: number,
        description: string,
        paymentMethods: string[][],
        active: boolean
    }
};

function toOption(opt) {
    return typeof opt === 'string' ? (
      <option value={!opt ? 'ENUM_' : opt.toUpperCase()}>{opt}</option>
    ) : (
      <option value={opt.value}>{opt.label}</option>
    );
  }
  
export const PlanDetail = (props: Props): JSX.Element => {
    const [id, setID] = React.useState(props.data.id);
    const [name, setName] = React.useState(props.data.name);
    const [currency, setCurrency] = React.useState(props.data.currency);
    const [period, setPeriod] = React.useState(props.data.period);
    const [recurring, setRecurring] = React.useState(props.data.recurring);
    const [signup, setSignup] = React.useState(props.data.signup);
    const [trialPeriod, setTrialPeriod] = React.useState(props.data.trialPeriod);
    const [oneOff, setOneOff] = React.useState(props.data.oneOff);
    const [description, setDescription] = React.useState(props.data.description);
    const [paymentMethods, setPaymentMethods] = React.useState(props.data.paymentMethods || []);
    const [active, setActive] = React.useState(props.data.active);


    const [numOfSet, setNumOfSet] = React.useState(props.data.paymentMethods?.length || 1);
    const [loading, setLoading] = React.useState(false);

    const [set1, setSet1] = React.useState((paymentMethods[0] || []).join(', '));
    const [set2, setSet2] = React.useState((paymentMethods[1] || []).join(', '));
    const [set3, setSet3] = React.useState((paymentMethods[2] || []).join(', '));
    const [set4, setSet4] = React.useState((paymentMethods[3] || []).join(', '));
    const [set5, setSet5] = React.useState((paymentMethods[4] || []).join(', '));

    // React.useEffect(() => {
    //     refresh();
    // }, []);

    // const refresh = () => {
    //     // setLoading(true);
    // };

    const handleSubmit = async (data) => {
        const validMethods = ['STRIPE', 'MOBILEPAY', 'QUICKPAY'];

        const currentMethods = [
            data.get('method1') || set1,
            data.get('method2') || set2,
            data.get('method3') || set3,
            data.get('method4') || set4,
            data.get('method5') || set5,
        ];
        const methods = [];
        for (let index = 0; index < numOfSet; index++) {
            const value = currentMethods[index];
            if(value) {
                methods.push(value.split(',').map((v) => v.trim().toUpperCase()).filter((v) => validMethods.includes(v)));
            }
        }

        const input = {
            id: data.get('id') || id,
            name: data.get('name') || name,
            currency: data.get('currency') || currency,
            period: data.get('period') || period,
            recurring: data.get('recurring') || recurring,
            signup: data.get('signup') || signup,
            trialPeriod: data.get('trialPeriod') || trialPeriod,
            description: data.get('description') || description,
            paymentMethods: methods,
            active: data.get('active') || active,
        };

        setLoading(true);
        const result = await apiClient.post('/api/microservice', { data: { action: 'UpdatePlan', data: { input } } });
        setID(result.data.id);
        setName(result.data.name);
        setCurrency(result.data.currency);
        setPeriod(result.data.period);
        setRecurring(result.data.recurring);
        setSignup(result.data.signup);
        setTrialPeriod(result.data.trialPeriod);
        setOneOff(result.data.oneOff);
        setDescription(result.data.description);
        setPaymentMethods(result.data.paymentMethods);
        setSet1((result.data.paymentMethods[0] || []).join(', '));
        setSet2((result.data.paymentMethods[1] || []).join(', '));
        setSet3((result.data.paymentMethods[2] || []).join(', '));
        setSet4((result.data.paymentMethods[3] || []).join(', '));
        setSet5((result.data.paymentMethods[4] || []).join(', '));
        setActive(result.data.active);

        setLoading(false);
    };

  return (
    <div style={{ padding: '20px' }}>
        {loading ?  <p>Loading...</p> : 
       ( <Form onSubmit={handleSubmit} submitTxt="Update Plan" 
            moreButtons={[
                { label: 'Add Payment Method Set', onClick: (data) => { 
                    if(numOfSet < 5) { 
                        setNumOfSet(numOfSet+1); 
                    }
                }}, 
                { label: 'Remove Payment Method Set', onClick: () => { 
                    if(numOfSet > 0) { 
                        setNumOfSet(numOfSet-1); 
                    }
                }}
            ]}
            >
            <FormInput
                key={'id'}
                name={'id'}
                label={'ID'}
                defaultValue={id}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'name'}
                name={'name'}
                label={'Name'}
                defaultValue={name}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'currency'}
                name={'currency'}
                label={'Currency'}
                defaultValue={currency}
                type = {'select'}
                labelSm={1}
                valueSm={10}>
                    {['DKK'].map(toOption)}
            </FormInput>
            <FormInput
                key={'period'}
                name={'period'}
                label={'Period'}
                defaultValue={period}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'recurring'}
                name={'recurring'}
                label={'Recurring'}
                defaultValue={recurring}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'signup'}
                name={'signup'}
                label={'Signup'}
                defaultValue={signup}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'trialPeriod'}
                name={'trialPeriod'}
                label={'Trial Period'}
                defaultValue={trialPeriod}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'oneOff'}
                name={'oneOff'}
                label={'One Off'}
                defaultValue={oneOff}
                type={'text'}
                disabled={true}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'description'}
                name={'description'}
                label={'Description'}
                defaultValue={description}
                type={'text'}
                labelSm={1}
                valueSm={10}
            />
            <FormInput
                key={'active'}
                name={'active'}
                label={'Active'}
                checked={active}
                type={'checkbox'}
                labelSm={1}
                valueSm={10}
            />
            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px'}}><strong>Payment Methods</strong></div>
            {numOfSet >= 1 ? <FormInput
                    key={'method' + 1}
                    name={'method' + 1}
                    label={'Set ' + 1}
                    defaultValue={set1}
                    type={'text'}
                    labelSm={1}
                    valueSm={10}
                /> : null}
            {numOfSet >= 2 ? <FormInput
                    key={'method' + 2}
                    name={'method' + 2}
                    label={'Set ' + 2}
                    defaultValue={set2}
                    type={'text'}
                    labelSm={1}
                    valueSm={10}
                /> : null}
            {numOfSet >= 3 ? <FormInput
                    key={'method' + 3}
                    name={'method' + 3}
                    label={'Set ' + 3}
                    defaultValue={set3}
                    type={'text'}
                    labelSm={1}
                    valueSm={10}
                /> : null}
            {numOfSet >= 4 ? <FormInput
                    key={'method' + 4}
                    name={'method' + 4}
                    label={'Set ' + 4}
                    defaultValue={set4}
                    type={'text'}
                    labelSm={1}
                    valueSm={10}
                /> : null}
            {numOfSet >= 5 ? <FormInput
                    key={'method' + 5}
                    name={'method' + 5}
                    label={'Set ' + 5}
                    defaultValue={set5}
                    type={'text'}
                    labelSm={1}
                    valueSm={10}
                /> : null}
        </Form>) }
    </div>
  );
};
